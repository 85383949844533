.main {
	margin: auto;
	position: relative;
	z-index: 30;
	&__preloader {
		&.is-hidden {
			display: none; } }
	&__result {
		display: none;
		&.is-active {
			display: block; } } }

.footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 40;
	@include image('craftpaper.jpg');
	@include cover;
	&__in {
		display: flex;
		align-items: center;
		height: 70px;
		@include sm {
			height: 100px; } }
	&__logo {
		margin-right: 22px;
		align-self: flex-end;
		@include sm {
			margin-bottom: 17px; }
		.logo {
			max-width: 95px; } }
	&__link {
		@include roboto-medium;
		font-size: 18px;
		color: #5A4632;
		@include trans;
		&:hover {
			opacity: .7; } } }

.auth {
	&__title {
		font-size: 30px;
		margin-bottom: 30px;
		padding: 0 20px;
		font-weight: normal;
		line-height: 1.4;
		color: #fff;
		@include futura;
		@include sm {
			font-size: 28px; } }
	&__image {
		margin-bottom: 20px; }
	&__message {
		margin-top: 18px;
		text-align: center;
		font-size: 10px;
		color: #fff; }
	.button_facebook {
		text-transform: uppercase; } }



.preloader {
	color: #fff;
	&__block {
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
		@include image('snow-bg.jpg');
		@include cover;
		border-radius: 4px;
		padding: 50px 60px;
		@include sm {
			background-position: 0 100%;
			padding: 30px; } }
	&__message {
		@include roboto-medium;
		font-size: 12px; } }
.progress {
	width: 100%;
	height: 13px;
	border-radius: 6px;
	background-color: #fff;
	position: relative;
	margin-bottom: 24px;
	margin-top: 77px;
	&__bar {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: $yellow-bright;
		width: 0%;
		border-radius: 6px; }
	&__value {
		font-size: 18px;
		position: absolute;
		top: 100%;
		margin-top: 24px;
		width: 100%;
		text-align: center; }
	&__image {
		max-width: 80px;
		position: absolute;
		bottom: -16px;
		margin-left: -30px; } }

.result {
	text-align: center;
	&__image {
		margin-bottom: 40px; }
	.button_facebook {
		margin-bottom: 18px; }
	.button_refresh {
		max-width: 322px;
		margin: 0 auto; } }

.final {
	&__in {
		display: flex;
		justify-content: space-between;
		@include sm {
			flex-direction: column; } }
	&__left {
		margin-right: 60px;
		@include sm {
			margin-right: 0; } }
	&__right {
		padding: 24px 0;
		@include sm {
			padding: 20px 0 12px; }
		.button {
			margin-bottom: 30px;
			@include sm {
				margin-bottom: 15px; } } }
	&__logo {
		max-width: 300px;
		margin-bottom: 0;
		@include sm {
			margin-bottom: 0; }
		img {
			max-width: 100%; } }
	.button_refresh {
		max-width: 242px;
		padding-right: 31px;
		margin-left: 33px;
		margin-top: -15px;
		@include sm {
			margin: 0 auto; } } }

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: none;
	&.is-active {
		display: block; }
	&__overlay {
		@include coverdiv;
		background-color: rgba(23,18,71,.7);
		z-index: 105; }
	&__img {
		width: 575px;
		height: 302px;
		@include cover;
		@include vcenter;
		z-index: 100;
		&.is-hidden {
			display: none; } }
	&__panel {
		width: 575px;
		height: 60px;
		@include vcenter;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 101% 108%;
		z-index: 110;
		margin-top: 240/2 + px;
		&.is-hidden {
			display: none; }
		&.is-active {
			&:before {
				opacity: 0; } }
		&:before {
			content: "";
			display: block;
			@include coverdiv;
			background-color: rgba(23,18,71,.7);
			@include trans(.4s); } }
	&__hint {
		@include vcenter;
		z-index: 120;
		width: 580px;
		opacity: 0;
		@include trans(.4s);
		&.is-active {
			opacity: 1; }
		.icon-add-friend {
			font-size: 24px;
			fill: #fff;
			position: relative;
			left: 10px;
			top: -20px;
			display: inline-block;
			vertical-align: top; }
		&-msg {
			@include roboto-bold;
			color: #fff;
			display: inline-block;
			vertical-align: top;
			position: relative;
			top: -12px;
			left: 20px; }
		&_left {
			margin-top: 45px;
			margin-left: -40px;
			.icon-arrow-left {
				width: 84px;
				height: 145px;
				stroke: #fff;
				display: inline-block;
				vertical-align: top; }
			.popup__hint-msg {
				top: -12px;
				left: 20px; } }
		&_right {
			margin-top: 80px;
			margin-left: 290px;
			.icon-arrow-right {
				width: 95px;
				height: 79px;
				stroke: #fff; }
			.popup__hint-msg {
				top: -8px;
				left: -10px; } } } }
