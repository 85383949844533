@function em($size, $context: 16) {
  @return ($size/$context) * 1em; }

@function img-url($image) {
  @return url('../img/#{$image}'); }

@mixin image($image) {
  background-image: img-url($image); }

@mixin trans($what: all, $dur: 0.2s, $easing: ease) {
  transition: $what $dur $easing; }

@mixin smooth-scroll {
  -webkit-overflow-scrolling: touch; }

@mixin smooth-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin gpu {
  transform: translate3d(0,0,0); }

@mixin md {
  @include r(1023) {
    @content; } }

@mixin sm {
  @include r(767) {
    @content; } }

@mixin xs {
  @include r(599) {
    @content; } }
