.l {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	padding: 0 20px; }
.out {
	display: flex;
	align-items: center;
	align-content: center;
	min-height: inherit;
	padding: 90px 0;
	@include sm {
		padding-bottom: 160px; } }
