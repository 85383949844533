body {
	background: $bg-gradient;
	font-size: 14px;
	color: #5A4632;
	position: relative;
	@include roboto-regular;
	line-height: 1.2;
	min-height: 100vh;
	@include font-smoothing;
	@include smooth-font; }

.parallax {
	@include coverdiv;
	overflow: hidden;
	z-index: 20;
	@include sm {
		display: none; }
	&__layer {
		position: absolute;
		object-fit: contain; } }


.lights {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 191px;
	z-index: 10;
	@include cover;
	@include sm {
		background-size: 300%;
		background-position: 50% 0; } }

.image {
	img {
		box-shadow: 0 10px 40px rgba(0,0,0,.2);
		width: 100%; } }

.button {
	appearance: none;
	border-radius: 5px;
	height: 45px;
	line-height: 45px;
	font-size: 14px;
	@include roboto-bold;
	position: relative;
	display: block;
	text-align: center;
	text-decoration: none;
	color: #fff;
	@include trans;
	&_facebook {
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
		background-color: $blue-facebook;
		padding: 0 90px;
		width: 100%;
		&:hover {
			box-shadow: 0 10px 30px rgba(0,0,0,.25); }
		@include sm {
			padding-left: 70px;
			padding-right: 31px; }
		.button__icon {
			left: 31px;
			@include sm {
				left: 21px; } }
		.icon {
			font-size: 2em;
			fill: #fff; } }
	&_refresh {
		border: 2px solid #fff;
		padding: 0 66px;
		background-color: transparent;
		line-height: 42px;
		color: #fff;
		&:hover {
			border-color: $yellow;
			.icon {
				fill: $yellow; } }
		.button__icon {
			left: 21px; }
		.icon {
			font-size: 1.79em;
			fill: #fff; } }
	&_craft {
		@include image('craftpaper-default.jpg');
		@include cover;
		max-width: 300px;
		padding: 0 5px;
		color: #5A4632;
		box-shadow: 0 1px 5px rgba(0,0,0,.2);
		span {
			position: relative;
			z-index: 2; }
		&:hover {
			box-shadow: 0 10px 30px rgba(0,0,0,.25);
			color: #fff;
			&:before {
				opacity: 1; } }
		&:before {
			content: "";
			display: block;
			@include coverdiv;
			@include cover;
			@include image('craftpaper-red.jpg');
			border-radius: 5px;
			opacity: 0;
			z-index: 1;
			@include trans; } }
	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		@include trans; }
	.icon {
		display: block; } }

