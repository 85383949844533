@include font('Roboto-Regular', 'roboto-regular-webfont');
@include font('Roboto-Medium', 'roboto-medium-webfont');
@include font('Roboto-Bold', 'roboto-bold-webfont');
@include font('Futura', 'futura-pt-book');

@mixin roboto-regular {
  font-family: "Roboto-Regular", Arial, Helvetica, sans-serif; }
@mixin roboto-medium {
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif; }
@mixin roboto-bold {
  font-family: "Roboto-Bold", Arial, Helvetica, sans-serif; }
@mixin futura {
  font-family: "Futura", Arial, Helvetica, sans-serif; }
