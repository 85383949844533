.icon-add-friend {
  width: 1.17em;
  height: 1em;
  fill: none;
}
.icon-arrow-left {
  width: 0.58em;
  height: 1em;
  fill: none;
}
.icon-arrow-right {
  width: 1.2em;
  height: 1em;
  fill: none;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-globe {
  width: 1em;
  height: 1em;
  fill: #515151;
}
.icon-pin {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-refresh {
  width: 0.96em;
  height: 1em;
  fill: #FFDC00;
}
.icon-smile {
  width: 1em;
  height: 1em;
  fill: #000;
}
