html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/futura-pt-book.woff2") format("woff2"), url("../fonts/futura-pt-book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.icon-add-friend {
  width: 1.17em;
  height: 1em;
  fill: none;
}

.icon-arrow-left {
  width: 0.58em;
  height: 1em;
  fill: none;
}

.icon-arrow-right {
  width: 1.2em;
  height: 1em;
  fill: none;
}

.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-globe {
  width: 1em;
  height: 1em;
  fill: #515151;
}

.icon-pin {
  width: 1em;
  height: 1em;
  fill: #000;
}

.icon-refresh {
  width: 0.96em;
  height: 1em;
  fill: #FFDC00;
}

.icon-smile {
  width: 1em;
  height: 1em;
  fill: #000;
}

body {
  background: linear-gradient(180deg, #acb9ff 0%, #96a5f6 100%);
  font-size: 14px;
  color: #5A4632;
  position: relative;
  font-family: "Roboto-Regular", Arial, Helvetica, sans-serif;
  line-height: 1.2;
  min-height: 100vh;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
}

@media only screen and (max-width: 767px) {
  .parallax {
    display: none;
  }
}

.parallax__layer {
  position: absolute;
  object-fit: contain;
}

.lights {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 191px;
  z-index: 10;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media only screen and (max-width: 767px) {
  .lights {
    background-size: 300%;
    background-position: 50% 0;
  }
}

.image img {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.button {
  appearance: none;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  font-family: "Roboto-Bold", Arial, Helvetica, sans-serif;
  position: relative;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease;
}

.button_facebook {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  background-color: #445281;
  padding: 0 90px;
  width: 100%;
}

.button_facebook:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 767px) {
  .button_facebook {
    padding-left: 70px;
    padding-right: 31px;
  }
}

.button_facebook .button__icon {
  left: 31px;
}

@media only screen and (max-width: 767px) {
  .button_facebook .button__icon {
    left: 21px;
  }
}

.button_facebook .icon {
  font-size: 2em;
  fill: #fff;
}

.button_refresh {
  border: 2px solid #fff;
  padding: 0 66px;
  background-color: transparent;
  line-height: 42px;
  color: #fff;
}

.button_refresh:hover {
  border-color: #ffdc00;
}

.button_refresh:hover .icon {
  fill: #ffdc00;
}

.button_refresh .button__icon {
  left: 21px;
}

.button_refresh .icon {
  font-size: 1.79em;
  fill: #fff;
}

.button_craft {
  background-image: url("../img/craftpaper-default.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  max-width: 300px;
  padding: 0 5px;
  color: #5A4632;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.button_craft span {
  position: relative;
  z-index: 2;
}

.button_craft:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.button_craft:hover:before {
  opacity: 1;
}

.button_craft:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../img/craftpaper-red.jpg");
  border-radius: 5px;
  opacity: 0;
  z-index: 1;
  transition: all 0.2s ease;
}

.button__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  transition: all 0.2s ease;
}

.button .icon {
  display: block;
}

.l {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
}

.out {
  display: flex;
  align-items: center;
  align-content: center;
  min-height: inherit;
  padding: 90px 0;
}

@media only screen and (max-width: 767px) {
  .out {
    padding-bottom: 160px;
  }
}

.main {
  margin: auto;
  position: relative;
  z-index: 30;
}

.main__preloader.is-hidden {
  display: none;
}

.main__result {
  display: none;
}

.main__result.is-active {
  display: block;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  background-image: url("../img/craftpaper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.footer__in {
  display: flex;
  align-items: center;
  height: 70px;
}

@media only screen and (max-width: 767px) {
  .footer__in {
    height: 100px;
  }
}

.footer__logo {
  margin-right: 22px;
  align-self: flex-end;
}

@media only screen and (max-width: 767px) {
  .footer__logo {
    margin-bottom: 17px;
  }
}

.footer__logo .logo {
  max-width: 95px;
}

.footer__link {
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #5A4632;
  transition: all 0.2s ease;
}

.footer__link:hover {
  opacity: .7;
}

.auth__title {
  font-size: 30px;
  margin-bottom: 30px;
  padding: 0 20px;
  font-weight: normal;
  line-height: 1.4;
  color: #fff;
  font-family: "Futura", Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 767px) {
  .auth__title {
    font-size: 28px;
  }
}

.auth__image {
  margin-bottom: 20px;
}

.auth__message {
  margin-top: 18px;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.auth .button_facebook {
  text-transform: uppercase;
}

.preloader {
  color: #fff;
}

.preloader__block {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  background-image: url("../img/snow-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 4px;
  padding: 50px 60px;
}

@media only screen and (max-width: 767px) {
  .preloader__block {
    background-position: 0 100%;
    padding: 30px;
  }
}

.preloader__message {
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.progress {
  width: 100%;
  height: 13px;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  margin-bottom: 24px;
  margin-top: 77px;
}

.progress__bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffc022;
  width: 0%;
  border-radius: 6px;
}

.progress__value {
  font-size: 18px;
  position: absolute;
  top: 100%;
  margin-top: 24px;
  width: 100%;
  text-align: center;
}

.progress__image {
  max-width: 80px;
  position: absolute;
  bottom: -16px;
  margin-left: -30px;
}

.result {
  text-align: center;
}

.result__image {
  margin-bottom: 40px;
}

.result .button_facebook {
  margin-bottom: 18px;
}

.result .button_refresh {
  max-width: 322px;
  margin: 0 auto;
}

.final__in {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .final__in {
    flex-direction: column;
  }
}

.final__left {
  margin-right: 60px;
}

@media only screen and (max-width: 767px) {
  .final__left {
    margin-right: 0;
  }
}

.final__right {
  padding: 24px 0;
}

@media only screen and (max-width: 767px) {
  .final__right {
    padding: 20px 0 12px;
  }
}

.final__right .button {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .final__right .button {
    margin-bottom: 15px;
  }
}

.final__logo {
  max-width: 300px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .final__logo {
    margin-bottom: 0;
  }
}

.final__logo img {
  max-width: 100%;
}

.final .button_refresh {
  max-width: 242px;
  padding-right: 31px;
  margin-left: 33px;
  margin-top: -15px;
}

@media only screen and (max-width: 767px) {
  .final .button_refresh {
    margin: 0 auto;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.popup.is-active {
  display: block;
}

.popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 18, 71, 0.7);
  z-index: 105;
}

.popup__img {
  width: 575px;
  height: 302px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.popup__img.is-hidden {
  display: none;
}

.popup__panel {
  width: 575px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 101% 108%;
  z-index: 110;
  margin-top: 120px;
}

.popup__panel.is-hidden {
  display: none;
}

.popup__panel.is-active:before {
  opacity: 0;
}

.popup__panel:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 18, 71, 0.7);
  transition: 0.4s 0.2s ease;
}

.popup__hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  width: 580px;
  opacity: 0;
  transition: 0.4s 0.2s ease;
}

.popup__hint.is-active {
  opacity: 1;
}

.popup__hint .icon-add-friend {
  font-size: 24px;
  fill: #fff;
  position: relative;
  left: 10px;
  top: -20px;
  display: inline-block;
  vertical-align: top;
}

.popup__hint-msg {
  font-family: "Roboto-Bold", Arial, Helvetica, sans-serif;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: -12px;
  left: 20px;
}

.popup__hint_left {
  margin-top: 45px;
  margin-left: -40px;
}

.popup__hint_left .icon-arrow-left {
  width: 84px;
  height: 145px;
  stroke: #fff;
  display: inline-block;
  vertical-align: top;
}

.popup__hint_left .popup__hint-msg {
  top: -12px;
  left: 20px;
}

.popup__hint_right {
  margin-top: 80px;
  margin-left: 290px;
}

.popup__hint_right .icon-arrow-right {
  width: 95px;
  height: 79px;
  stroke: #fff;
}

.popup__hint_right .popup__hint-msg {
  top: -8px;
  left: -10px;
}
